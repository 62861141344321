import gsap from 'gsap';

export const transitions = [
  {
    name: 'default-transition',
    async leave(data) {
      await gsap.to(data.current.container, {
        opacity: 0
      });
    },
    async enter(data) {
      await gsap.from(data.next.container, {
        opacity: 1
      });
    }
  }
]
