import SplitType from 'split-type'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)


export const transition1 = (el) => {

  // Title 
  const title = el.querySelector('.transition-1-title > *')
  const splitTitle = title ? new SplitType(title, { 
    by: 'words' 
  }) : undefined
  // Riposiziono dopo lo split
  window.dispatchEvent(new Event('resize'))


  // Image
  const image = el.querySelector('.transition-1-image')

  // Text
  const text = el.querySelectorAll('.transition-1-text')
  const tl = gsap.timeline({ 
    scrollTrigger: {
      trigger: el,
      start: "top 85%",
      toggleActions: "play complete none none"
    }
  })
  tl.fromTo(el,{
    opacity: 0,
  }, 
  {
    opacity: 1,
    duration: 0,
  })
  if(splitTitle) {
    tl.fromTo(splitTitle.words,{
      y: '110%',
      skewY: 7,
    }, 
    {
      y: '0%',
      skewY: 0,
      duration: 1.8,
      ease: 'power4.out',
      stagger: {
        amount: 0.3,
      },
      onComplete: () => {
        window.addEventListener('resize', () => {
          splitTitle.split({ 
            by: 'words' 
          })
        })
      }
    })
    .addLabel("animate", "-=0.8")
  }
  if(image) {
    tl.fromTo(image,{
      opacity: 0,
    }, 
    {
      opacity: 1,
      duration: 1.2,
      ease: 'power2.out',
      delay: () => splitTitle ? 0 : 0.6
    }, "animate")
  }
  if(text) {
    tl.fromTo(text,{
      opacity: 0,
    }, 
    {
      opacity: 1,
      duration: 1.2,
      ease: 'power2.out',
      delay: () => splitTitle ? 0 : 0.6
    }, "animate")
  }
  
}