
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import { transitions} from  './components/page-transitions.js'
import { transitionIntro } from './components/intro.js'
import { transition1 } from './components/transitions.js'
import { customCursor } from './components/cursor.js'
import { navigationScroll, navigationOverlayVisibility, updateMenu } from './components/navigation.js'
import { initFlickity, initClickGallery } from './components/slider.js'
import { initShareButtons } from './components/share.js'
import { parallax } from './components/parallax.js'

import { gsap } from 'gsap'

/* Navigation Large */
const navigationLarge = document.querySelector('.main-nav-large')
const navigationSmall = document.querySelector('.main-nav-small')
const navigationOverlay = document.querySelector('.main-nav-overlay')
navigationScroll(navigationLarge)
navigationScroll(navigationSmall)
navigationOverlayVisibility(navigationSmall, navigationOverlay)

const pageFunctions = () => {
  if(typeof addthis !== 'undefined' && typeof addthis.layers?.refresh === 'function') { addthis.layers?.refresh(); }


  /* Cursor */
  const cursors = document.querySelectorAll('.custom-cursor')
  cursors.forEach(cursor => {
    customCursor(cursor)
  })
  const transition1Items = document.querySelectorAll('.transition-1')
  transition1Items.forEach(item => {
    transition1(item)
  })

  /* News */
  const newsSliders = document.querySelectorAll('.news-slider-flickity')
  newsSliders.forEach(slider => {
    initFlickity(slider, {
      cellAlign: 'left',
      prevNextButtons: false,
      groupCells: true,
      draggable: true
    })
  })
  
  /* Click Gallery */
  const galleries = document.querySelectorAll('.gallery-click')
  galleries.forEach(gallery => {
    initClickGallery(gallery)
  })

  /* Share Links */
  var shareButtons = document.querySelectorAll(".share-btn");
  if (shareButtons) {
    shareButtons.forEach(button => initShareButtons(button))
  }

  /* parallax Images Menu */
  const imagesMenuParallax = document.querySelectorAll('.menu-dishes .static-image-decor')
  if(imagesMenuParallax.length){
    parallax(imagesMenuParallax, '.menu-dishes')
  }

  /* parallax Images Rooms */
  const imagesRoomsParallax = document.querySelectorAll('.rooms-features .static-image-decor')
  if(imagesRoomsParallax.length){
    parallax(imagesRoomsParallax, '.rooms-features')
  }
}

/* Intro */
const showIntro = true
const intro = document.querySelector('.intro')

if(showIntro){
  transitionIntro(intro).play().then(() => {
    pageFunctions()
  })
} else{
  gsap.set(intro, { autoAlpha: 0 })
  pageFunctions()
}




/* Barbajs behaviour */
barba.use(barbaPrefetch);

barba.init({
  transitions,
  timeout: 20000,
    requestError: (trigger, action, url, response) => {
      // go to a custom 404 page if the user click on a link that return a 404 response status
      if (action === 'click' && response.status && response.status === 404) {
        barba.go('/404');
      }
  
      // prevent Barba from redirecting the user to the requested URL
      // this is equivalent to e.preventDefault() in this context
      return false;
    },
}); 
  
barba.hooks.leave((data) => {
  window.scrollTo(0, 0)
})

barba.hooks.after((data) => pageFunctions())

barba.hooks.before((data) => {
  updateMenu(data.trigger.href)
});
