import SplitType from 'split-type'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export const navigationScroll = (el) => { 
  ScrollTrigger.create({
    start: `top ${el.getBoundingClientRect().height * -1}`,
    end: 99999,
    toggleClass: {targets: el, className: "small"}
  });

  ScrollTrigger.create({
    start: `top ${el.getBoundingClientRect().height * -2}`,
    end: 99999,
    toggleClass: {targets: el, className: 'scrolled' },
  });

  ScrollTrigger.create({
    start: `top ${el.getBoundingClientRect().height * -4}`,
    end: 99999,
    toggleClass: {targets: el, className: 'scrolled--down' },
  });
}

export const navigationOverlayVisibility = (menu, overlay) => { 
  const triggerOpen = menu.querySelector('.nav__burger')
  const triggerClose = overlay.querySelector('.main-nav-overlay__close')
  const links = overlay.querySelectorAll('.navigation-link-overlay')
  const splitLinks = new SplitType(links, {
    types: 'words',
  })

  const tl = gsap.timeline({
    totalDuration: 1.4,
    ease: 'power2.out',
    paused: true,
    onComplete: () => {
      document.body.classList.add('nav-overlay-open')
    },
    onReverseComplete: () => {
      document.body.classList.remove('nav-overlay-open')
    }
  })
  
  tl.to(overlay, { autoAlpha: 1 })
  tl.from(
    splitLinks.words,
    {
      y: '110%',
      skewY: 7,
      stagger: {
        amount: 0.3,
      },
    }
  )
  tl.set(document.body, { overflow: 'hidden' })

  triggerOpen.addEventListener('click', (e) => {
    tl.play()
  })

  triggerClose.addEventListener('click', (e) => {
    tl.reverse()
  })

  links.forEach(link => {
    link.addEventListener('click', (e) => {
      tl.reverse()
    })
  })
  
}


export const  updateMenu = (url) => {
  const active = document.querySelectorAll('.main-nav-large__links a.active, .main-nav-overlay__links a.active');

  if (active.length) {
    active.forEach(el => el.classList.remove('active'))
  }

  const links = Array.from(document.querySelectorAll('.main-nav-large__links a, .main-nav-overlay__links a'));

  links.forEach(link => { 
    if(link.href === url){
      link.classList.add('active');
    } 
  })
}
