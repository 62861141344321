import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

export const parallax = (els, wrapperClass) => {
  /*** Different ScrollTrigger setups for various screen sizes (media queries) ***/
  ScrollTrigger.matchMedia({
    "(min-width: 1200px)": () => {
      
      els.forEach(el => {
        gsap.to(el, {
          yPercent: -100,
          ease: "none",
          scrollTrigger: {
            trigger: el.closest(wrapperClass),
            scrub: true
          }, 
        });
      });
    }, 

    // mobile
    "(max-width: 1199px)": () => {
    
    }
  });
} 