import SplitType from 'split-type'
import { gsap } from 'gsap'

export const transitionIntro = (el) => {

  // Title 
  const wrap = el.querySelector('.intro__wrap')
  const text = el.querySelector('.transition-intro-text > *')
  const splitText = new SplitType(text, { 
    by: 'words' 
  }) 

  // Riposiziono dopo lo split
  window.dispatchEvent(new Event('resize'))
  const tl = gsap.timeline({ paused: true })

  tl.set(document.body, { overflow: 'hidden' })
  tl.set(wrap, { opacity: 1 })
  tl.fromTo(splitText.words,{
    opacity: 0
  }, 
  {
    opacity: 1,
    stagger: {
      each: 1,
    }
  })
  tl.fromTo(el,{
    autoAlpha: 1
  }, 
  {
    autoAlpha: 0,
    duration: 1,
    delay: 0.6,
    ease: 'power2.out',
  })
  tl.set(document.body, { overflow: 'auto' })

  return tl
}