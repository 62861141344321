import Flickity from 'flickity';
import "../../../node_modules/flickity/css/flickity.css";



export const initFlickity = (el, options = {}) => {
 const flickityOptions =  Object.assign({}, options, {
    on: {
      dragStart: () => {
        document.ontouchmove = (e) => e.preventDefault()
        slider
          .getCellElements()
          .forEach((slide) => (slide.style.pointerEvents = 'none'))
      },
      dragEnd: () => {
        document.ontouchmove = () => true
        slider
          .getCellElements()
          .forEach((slide) => (slide.style.pointerEvents = 'all'))
      },
    },
  })

  const slider = new Flickity(el, flickityOptions)
}

export const initClickGallery = (el) => {
  const slider = el
  const slides = el.querySelectorAll('.gallery-click__slide')
  const length = slides.length - 1
  let selected = 0
  let next = 1

  slider.addEventListener('click', () => {
    if(selected < length){
      selected = selected + 1
    } else {
      selected = 0
    }
    next = selected === length ? 0 : selected + 1

    slides.forEach((slide, index) => {
      slide.classList.remove('--visible')
      slide.classList.remove('--visible--next')
      if(index === selected){
        slide.classList.add('--visible')
      } 
      else if(index === next){
        slide.classList.add('--visible--next')
      }
    });
  }, false)
}
