import { gsap } from 'gsap'

export const customCursor = (el) => {


  document.addEventListener('mousemove', (e) => {
    gsap.to(el, {
      x: e.clientX - el.getBoundingClientRect().width / 2,
      y: e.clientY - el.getBoundingClientRect().height / 2,
      duration: 0.3,
      ease: 'power2.easeOut',
    })
  })

  document.addEventListener('mouseover', (e) => {
    const target = e.target.closest('[data-pointer]')
    const type = target !== null ? target.dataset.pointer : 'default'
    if(document.body.dataset.cursor !== type){
      document.body.dataset.cursor = type
    }
  })
}
